export const currencyFormatter = (format, number) => {
  const result = new Intl.NumberFormat(format).format(number)
  switch (format) {
    case "ID":
      return `Rp ${result}`

    default:
      return `${result}`
  }
}
