import React from "react"
import { Button, Input, Modal, Image } from "@components/anti"

import ModalAuthImg from "@public/img/sample/modal-auth.png"
import { F_Q_CARTS_SHIPPING, F_Q_CARTS, F_M_ADD_NOTIFY } from "lib/function"
import { useRouter } from "next/router"
import { useDispatch } from "react-redux"
import {
  SET_SHOW_TOAST,
  SET_TEXT_TOAST,
  SET_TYPE_TOAST,
  SET_CARTS,
} from "lib/redux/types"
import { Formik } from "formik"
import * as Yup from "yup"

const ModalGuestNotify = ({ id, show, hide, callback, token, sku_id }) => {
  const [email, setEmail] = React.useState(null)
  const [btnLoading, setBtnLoading] = React.useState(false)
  const dispatch = useDispatch()
  const { locale } = useRouter()

  const handleAddNotify = async (values, { resetForm, setSubmitting }) => {
    try {
      setBtnLoading(true)
      let { data, errors } = await F_M_ADD_NOTIFY(
        { email: values.email, sku_id },
        token
      )
      setBtnLoading(false)
      if (data) {
        hide()
        dispatch({ type: SET_SHOW_TOAST, payload: true })
        dispatch({ type: SET_TYPE_TOAST, payload: "success" })
        dispatch({
          type: SET_TEXT_TOAST,
          payload: data?.addToNotify?.message,
        })
        window.scrollTo(0, 0)
        setTimeout(() => {
          dispatch({ type: SET_SHOW_TOAST, payload: false })
        }, 3000)
        resetForm()
      } else {
        //!NEED ERROR HANDLING
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      id={id}
      isShowing={show}
      hide={hide}
      className="modal-sm"
      contentClassName="p-0 m-0"
    >
      <div className="modal-auth">
        <div className="img-wrapper">
          <h2 className="title-modal">NAGAREY.COM</h2>
          <Image src={ModalAuthImg} className="img-fluid" />
        </div>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email(
                locale === "id"
                  ? "Format email tidak valid"
                  : "Invalid email format"
              )
              .required(
                locale === "id" ? "Email wajib diisi" : "Email required"
              ),
          })}
          onSubmit={handleAddNotify}
        >
          {({ handleSubmit, handleChange, touched, errors, values }) => (
            <form onSubmit={handleSubmit} className="p-4" noValidate>
              <Input
                type="email"
                label="EMAIL"
                name="email"
                placeholder="Enter your email"
                onChange={handleChange}
                errors={errors}
                touched={touched}
                value={values.email}
              />
              <Button
                loader
                disabled={!Boolean(values.email)}
                // onClick={handleAddNotify}
                className={`mt-4 w-100 ${btnLoading ? "loading" : ""}`}
                type={"submit"}
              >
                REMIND ME
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

export default ModalGuestNotify

// import { useState } from "react"

// const ModalPromo = ({ id, show, hide, callback }) => {
//   const [appliedPromo, setAppliedPromo] = useState([])
//   const [listPromo, setListPromo] = useState([
//     {
//       text: "NAGAREY123VA",
//       subText: "Get 10% OFF with min. purchase Rp 500K",
//       discount: 10,
//     },
//     {
//       text: "NAGAREY123VB",
//       subText: "Get 10% OFF with min. purchase Rp 500K",
//       discount: 10,
//     },
//     {
//       text: "NAGAREY123VC",
//       subText: "Get 10% OFF with min. purchase Rp 500K",
//       discount: 10,
//     },
//     {
//       text: "NAGAREY123VD",
//       subText: "Get 10% OFF with min. purchase Rp 500K",
//       discount: 10,
//     },
//   ])

//   const handleRemovePromo = (val) => {
//     setListPromo((item) => [...item, val])
//     let temp = appliedPromo.filter((item) => !(item.text === val.text))
//     setAppliedPromo(temp)
//   }

//   const handleChoosePromo = (val) => {
//     setAppliedPromo((item) => [...item, val])
//     let temp = listPromo.filter((item) => !(item.text === val.text))
//     setListPromo(temp)
//   }

//   const handleSave = () => {
//     hide()
//     callback && callback(appliedPromo)
//   }
//   return (
//     <Modal
// className="modal-lg"
// id={id}
// isShowing={show}
// hide={hide}
// contentClassName="p-0 m-0"
//     >
//       <div className="modal-promo">
//         <div className="header-wrapper p-4">
//           <h2 className={`modal-title fadeInUp d1`}>Enter a Promo Code</h2>
//           <form className="form-promo-code">
//             <div className="row align-items-end mt-4">
//               <div className="col-9 col-md-10">
//                 <Input
// label="PROMO CODE"
// placeholder="Enter your promotional code"
//                 />
//               </div>
//               <div className="col-3 col-md-2">
//                 <Button className="w-100" variant="primary">
//                   APPLY
//                 </Button>
//               </div>
//             </div>
//           </form>
//         </div>
//         <div className="p-4">
//           <div className="applied-wrapper">
//             <h4>Applied Promo</h4>
//             <div className="row list-applied-promo">
//               {appliedPromo.map((item, i) => (
//                 <div
//                   className="col-md-6 col-12 fadeInUp d1"
//                   key={`promo-applied-item-${i}`}
//                 >
//                   <div className="promo-item">
//                     <span className="text-promo">{item.text}</span>
//                     <br />
//                     <span className="subtext-promo">{item.subText}</span>
//                     <span
//                       class="air ai-trash"
//                       onClick={() => handleRemovePromo(item)}
//                     ></span>
//                     <div className="auto-apply">AUTO APPLY</div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>

//           <div className="list-promo-wrapper mt-4">
//             <h4>Your Promo Coupons ({listPromo.length})</h4>
//             <div className="row list-promo">
//               {listPromo.map((item, i) => (
//                 <div className="col-md-6 col-12" key={`promo-item-${i}`}>
//                   <div
//                     className="promo-item fadeInUp d1"
//                     onClick={() => handleChoosePromo(item)}
//                   >
//                     <span className="text-promo">{item.text}</span>
//                     <br />
//                     <span className="subtext-promo">{item.subText}</span>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//           <Button className="w-100" variant="primary" onClick={handleSave}>
//             DONE
//           </Button>
//         </div>
//       </div>
//     </Modal>
//   )
// }

// export default ModalPromo
